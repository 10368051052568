/* eslint-disable react/prefer-stateless-function */
// Allow creation of class component, so that refs can still be used on wrapped components
import React from 'react';
import { SelectedContext } from 'ComponentLibrary/context';
import HocUtil from 'Utils/hoc-util';

function withSelected(Component) {
  class WrappedComponent extends React.Component {
    render() {
      return (
        <SelectedContext.Consumer>
          {(selectedId) => (
            <Component {...this.props} selectedId={selectedId} />
          )}
        </SelectedContext.Consumer>
      );
    }
  }

  // Proxy the wrapped component displayName
  WrappedComponent.displayName = HocUtil.displayName(Component);

  return WrappedComponent;
}

export default withSelected;
