import React from 'react';
import Icon from './icon';

function EditIcon(props) {
  return (
    <Icon
      name="edit"
      size={20}
      viewBox="0 0 22 22"
      {...props}
      renderComponent={({ color }) => (
        <g
          className="icon-group-outer"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g stroke={color} className="icon-group-inner">
            <g transform="translate(10.500000, 11.500000) rotate(-315.000000) translate(-10.500000, -11.500000) translate(8.000000, 1.000000)">
              <path d="M1.00674696,-1.77635684e-15 C0.450735967,-1.77635684e-15 5.86197757e-14,0.443446793 5.86197757e-14,0.999751629 L5.86197757e-14,16.5789474 L2.54785156,20.4473684 L5.09570313,16.5789474 L5.09570313,0.999751629 C5.09570313,0.447604051 4.64349347,-1.77635684e-15 4.08895616,-1.77635684e-15 L1.00674696,-1.77635684e-15 Z" />
              <path
                d="M0.509570313,16.0263158 L4.58613281,16.0263158"
                strokeLinecap="square"
              />
              <path d="M1.42464193,3.5 L4.5,3.5" strokeLinecap="square" />
            </g>
          </g>
        </g>
      )}
    />
  );
}

export default EditIcon;
