import React from 'react';
import PropTypes from 'prop-types';

import withT from 'ComponentLibrary/hocs/withT';

const AnalyticsIcon = React.forwardRef(
  ({ size, color, className, toolTip, onClick, t }, ref) => {
    const height = size;
    const width = height * (8 / 9);
    return (
      <svg
        ref={ref}
        className={className}
        aria-labelledby="analytics-icon-title"
        width={width}
        height={height}
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        onClick={onClick}
      >
        <title id="analytics-icon-title">{toolTip || t('analytics')}</title>
        <g stroke="none" fill={color} fillRule="evenodd">
          <g transform="translate(-189.000000, -342.000000)">
            <g transform="translate(76.000000, 338.000000)">
              <g transform="translate(110.000000, 0.000000)">
                <rect
                  x="18.1376527"
                  y="3.99674795"
                  width="2.09926047"
                  height="16"
                />
                <rect
                  x="15.2627395"
                  y="9.52769131"
                  width="2.09926047"
                  height="10.4755607"
                />
                <rect
                  x="12.3878264"
                  y="7.62277063"
                  width="2.09926047"
                  height="12.3804814"
                />
                <rect
                  x="9.51291317"
                  y="13.5631352"
                  width="2.09926047"
                  height="6.44011689"
                />
                <rect
                  x="6.63799999"
                  y="11.5572119"
                  width="2.09926047"
                  height="8.44604015"
                />
                <rect
                  x="3.7630868"
                  y="16.7831936"
                  width="2.09926047"
                  height="3.22005844"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
);

AnalyticsIcon.displayName = 'AnalyticsIcon';

AnalyticsIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  toolTip: PropTypes.string,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired,
};

AnalyticsIcon.defaultProps = {
  size: 18,
  color: '#999',
  className: '',
  onClick() {},
};

export default withT(AnalyticsIcon);
