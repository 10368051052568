import React, { Component } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import styled from '@emotion/styled';

// Utils
import { truncate } from 'Style/truncate';
import { TEXT_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { euc } from 'Utils/url';
import { KEYS } from 'Utils/window-key-down';
import { getImageObjectFromSection } from 'Utils/image-util';
import {
  getEditorUrl,
  getMagazineAnalyticsUrl,
} from 'Utils/content/flipboard-urls';
import { pushPathToHistory } from 'Utils/history';
import { USAGE_NAV_FROMS, USAGE_EVENT_NAMES } from 'Utils/analytics/usage';
import SectionUtil from 'Utils/content/section-util';

// Components
import { NavFromContext } from 'ComponentLibrary/context';
import WebLink from './web-link';
import SectionLink from './section-link';
import Image from './base/image';
import PrivateIcon from 'ComponentLibrary/icons/private';
import EditIcon from 'ComponentLibrary/icons/edit';
import AnalyticsIcon from 'ComponentLibrary/icons/analytics';
import VerifiedCheckmarkIcon from 'ComponentLibrary/icons/verified-checkmark';
import withSelected from '../hocs/withSelected';
import SourceAttribution from 'Webapp/shared/app/components/attribution/source';
import PinnedTag from 'Webapp/shared/app/components/item/card/pinned-tag';

import withNavFrom from '../hocs/withNavFrom';
import withT from 'ComponentLibrary/hocs/withT';
import withHistory from 'Webapp/shared/app/hocs/withHistory';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectSectionCover from 'Webapp/shared/app/connectors/connectSectionCover';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectUsageTrackSectionTap from 'Webapp/shared/app/connectors/connectUsageTrackSectionTap';

const StyledSectionLink = styled(SectionLink)({
  '.section-tiles__title': truncate('92%', 5),
});

const StyledSourceAttribution = styled(SourceAttribution)({
  color: TEXT_COLORS.overlay,
});

const StyledPinnedTag = styled(PinnedTag)({
  position: 'absolute',
  bottom: SPACING.LARGE,
  left: SPACING.LARGE,
});

class SectionTile extends Component {
  constructor(props) {
    super(props);

    this.tile = React.createRef();
    this.tileImage = this.tileImage.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    const { section, getSectionCover } = this.props;
    if (!this.tileImage()) {
      getSectionCover(section.remoteid);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedId === this.props.selectedId) {
      return;
    }
    if (this.props.section.remoteid === this.props.selectedId) {
      this.tile.current.focus();
    }
  }

  tileImage() {
    const { image, section } = this.props;
    return image || getImageObjectFromSection(section);
  }

  handleKeyPress(event) {
    switch (event.keyCode) {
      case KEYS.ENTER: {
        const { history, section } = this.props;
        const sectionPath = section.canonicalPath;
        sectionPath && pushPathToHistory(sectionPath, history);
        break;
      }
    }
  }

  render() {
    const {
      item,
      section,
      selectedId,
      isAmp,
      isOwnProfile,
      shownInFeed,
      navFromEventName,
      usageTrackSectionTap,
      includeSectionAttribution,
      isPhone,
      imageOnly,
      t,
    } = this.props;
    const isSelected = selectedId === section.remoteid;
    const { title } = section;
    const selectedClass = className({
      'section-tiles__tile-wrapper': true,
      'section-tiles__tile-wrapper--selected': isSelected,
    });
    const tileClasses = className({
      'section-tiles__tile': true,
      'section-tiles__tile--in-feed': shownInFeed,
      'media-link': true,
    });
    const titleClasses = className({
      'section-tiles__title': true,
      'ui-text--title--small': !shownInFeed,
      'ui-text--title': shownInFeed,
    });
    const alt = title ? `${title} - ${t('cover')}` : t('magazine_cover');

    let decoratedTitle;
    if (includeSectionAttribution) {
      decoratedTitle = section.isTopic
        ? `#${section.topicTag}`
        : section.author.authorDisplayName;
    } else {
      decoratedTitle = SectionUtil.getDecoratedSectionTitle(section);
    }
    const tileProps = { ref: this.tile };
    if (isSelected) {
      tileProps.tabIndex = 0;
      tileProps.onKeyDown = this.handleKeyPress;
    }
    const canEditMagazine =
      !isAmp && isOwnProfile && section.isMagazine && !isPhone;
    const image = this.tileImage();

    return (
      <div {...tileProps} className={selectedClass}>
        <StyledSectionLink
          className={tileClasses}
          item={item}
          section={section}
          navFromEventName={navFromEventName}
          onClick={usageTrackSectionTap}
        >
          <div className="section-tiles__image">
            {image && <Image image={image} alt={alt} crop />}
          </div>
          {!imageOnly && (
            <>
              <div className="section-tiles__tile-overlay" />
              <div className="section-tiles__tile-content">
                <>
                  <h3 className={titleClasses}>{decoratedTitle}</h3>
                  {includeSectionAttribution && (
                    <StyledSourceAttribution
                      item={item}
                      section={section}
                      noLink
                    />
                  )}
                </>
                {section.isMagazine && section.authorDisplayName && (
                  <h4 className="section-tiles__author ui-text--supporting-emphasis">
                    By {section.authorDisplayName}
                  </h4>
                )}
                {section.isVideoSection && (
                  <h4 className="section-tiles__author ui-text--supporting-emphasis">
                    {section.title || t('video_plural')}
                  </h4>
                )}
              </div>
              <StyledPinnedTag item={item} section={section} />
            </>
          )}
        </StyledSectionLink>
        <div className="section-tiles__icons">
          {section.isProfile && section.isVerifiedPublisher && (
            <span className="section-tiles__icon">
              <VerifiedCheckmarkIcon className="section-tiles__verified-icon" />
            </span>
          )}
          <div className="section-tiles__multi-icon-wrapper">
            {canEditMagazine && !section.isPrivate && (
              <NavFromContext.Provider
                value={USAGE_NAV_FROMS.PROFILE_SECTION_TILE}
              >
                <WebLink
                  className="section-tiles__icon"
                  href={getMagazineAnalyticsUrl(
                    section.author.authorUsername,
                    euc(section.remoteid),
                  )}
                  navFromEventName={USAGE_EVENT_NAMES.ANALYTICS_ENTER}
                >
                  <AnalyticsIcon color="#fff" />
                </WebLink>
              </NavFromContext.Provider>
            )}
            {section.isPrivate && (
              <span className="section-tiles__private-icon section-tiles__icon">
                <PrivateIcon color="#fff" />
              </span>
            )}
          </div>
          {canEditMagazine && (
            <WebLink
              className="section-tiles__edit-icon section-tiles__icon"
              href={getEditorUrl(section)}
            >
              <EditIcon color="#fff" />
            </WebLink>
          )}
        </div>
      </div>
    );
  }
}

SectionTile.propTypes = {
  item: PropTypes.object,
  section: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedId: PropTypes.string,
  isAmp: PropTypes.bool,
  isOwnProfile: PropTypes.bool,
  shownInFeed: PropTypes.bool,
  navFromEventName: PropTypes.string,
  getSectionCover: PropTypes.func.isRequired,
  image: PropTypes.object,
  usageTrackSectionTap: PropTypes.func.isRequired,
  includeSectionAttribution: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
  imageOnly: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

SectionTile.defaultProps = {
  item: null,
  selectedId: null,
  isAmp: false,
  isOwnProfile: false,
  shownInFeed: false,
  navFromEventName: null,
  imageOnly: false,
};

export default connector(
  connectUsageTrackSectionTap,
  connectAmp,
  connectSectionCover,
  connectResponsive,
)(withT(withHistory(withSelected(withNavFrom(SectionTile)))));
