import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { COLORS } from 'Style/colors';
// Components
import CheckmarkIcon from './checkmark';
import withT from 'ComponentLibrary/hocs/withT';

const StyledIcon = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'auto',
  height: 'auto',
  padding: SPACING.SMALL,
  borderRadius: '50%',
  background: COLORS.red,
});

function VerifiedCheckmarkIcon({
  innerSize,
  toolTip,
  t,
  className,
  customStyle,
}) {
  // TODO: find custom style to add here. ex. profile-header.js and profile-header.scss
  const classes = classnames(className, {
    'verified-checkmark-icon': true,
  });
  return (
    <StyledIcon
      className={classes}
      role="img"
      aria-labelledby="verified-checkmark-icon-title"
      customStyle={customStyle}
    >
      <title id="verified-checkmark-icon-title">
        {toolTip || t('verified')}
      </title>
      <CheckmarkIcon color="#fff" size={innerSize} />
    </StyledIcon>
  );
}

VerifiedCheckmarkIcon.propTypes = {
  className: PropTypes.string,
  toolTip: PropTypes.string,
  innerSize: PropTypes.number,
  t: PropTypes.func.isRequired,
  customStyle: PropTypes.object,
};
VerifiedCheckmarkIcon.defaultProps = {
  innerSize: 15,
};

export default withT(VerifiedCheckmarkIcon);
